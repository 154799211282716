// src/components/Champion.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
// Replace with your actual image path or default image
import defaultUserImage from "../../images/defaultChampion.jpg";

const Champion = () => {
  const [carouselContent, setCarouselContent] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Helper function to convert ArrayBuffer to Base64
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make the GET request to fetch champions
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/champions`,
          {
            responseType: "arraybuffer", // Ensure binary data is received
          }
        );

        // Decode the ArrayBuffer response into JSON
        const data = JSON.parse(
          new TextDecoder("utf-8").decode(new Uint8Array(response.data))
        );

        // Process each champion to convert binary image data to Base64
        const processedData = data.map((champion) => {
          let imageSrc = defaultUserImage;

          if (champion?.image && champion?.image?.data) {
            // Convert binary data to Base64
            const base64String = arrayBufferToBase64(champion.image.data); // Access the image data directly
            // Determine the image type (assuming JPEG; adjust if necessary)

            imageSrc = `data:image/jpeg;base64,${base64String}`;
          }

          return {
            ...champion,
            imageSrc,
          };
        });

        setCarouselContent(processedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch champions. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Automatically move to the next slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // 5 seconds

    return () => clearInterval(interval);
  }, [carouselContent]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === carouselContent.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselContent.length - 1 : prevIndex - 1
    );
  };

  // Render loading state
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-xl font-semibold">Loading champions...</p>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <p className="text-red-500 text-xl mb-4">{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="relative lg:max-w-full mb-20 overflow-hidden">
      <p className="text-4xl p-5 text-center flex justify-center mt-24 mb-10 md:text-5xl lg:text-6xl font-black text-blue-700">
        ELiNA's Community Spotlight
      </p>
      <div className="flex items-center justify-center lg:gap-20">
        {/* Previous Slide Button */}
        <button className="bg-transparent border-none z-10" onClick={prevSlide}>
          <ArrowLeftIcon
            className={`w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 text-gray-600`}
          />
        </button>

        {/* Carousel Slides */}
        <div className="max-w-full sm:max-w-xl md:max-w-3xl lg:max-w-5xl overflow-hidden">
          <div
            className="flex transition-transform duration-[2000ms] ease-in-out"
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            {carouselContent.map((item, index) => (
              <div
                key={item.id || index} // Use a unique key; fallback to index if id is unavailable
                className="w-full flex-shrink-0 flex flex-col items-center "
              >
                <div className="grid grid-cols-10 w-full lg:min-h-[500px]">
                  {/* Left Side: Champion Details */}
                  <div
                    className="col-span-10 sm:col-span-5 md:col-span-4 lg:col-span-3 bg-pink-600 md:gap-2 sm:gap-1
           p-4 sm:p-6 md:p-8 flex flex-col justify-center items-center"
                  >
                    {/* Champion Image */}
                    <div className="w-full flex justify-center mb-4">
                      <img
                        className="rounded-full w-36 h-36  md:w-36 md:h-36 lg:w-44 lg:h-44 bg-red-500"
                        src={item?.imageSrc}
                        alt={item?.name}
                      />
                    </div>

                    {/* Champion Name */}
                    <div className="text-center text-xl sm:text-xl md:text-2xl lg:text-3xl text-white font-bold mb-2">
                      <p>{item.name}</p>
                    </div>

                    {/* Champion Role */}
                    <div className="text-white text-center text-md sm:text-lg md:text-xl font-semibold">
                      <p>{item.role}</p>
                    </div>
                  </div>

                  {/* Right Side: Award Details */}
                  <div className="col-span-10 sm:col-span-5 md:col-span-6 lg:col-span-7 bg-white  p-4 sm:p-6 md:p-8 lg:p-14 HeroBg">
                    {/* Award Name */}
                    <div className="text-lg  md:text-4xl lg:text-5xl text-blue-700 pb-2 sm:pb-4 md:pb-6 font-bold">
                      <p>{item.award_name}</p>
                    </div>

                    {/* Award Description */}
                    <div className="text-md sm:text-lg md:text-2xl lg:text-2xl pb-1 sm:pb-2 md:pb-4 font-normal">
                      <p>{item.award_description}</p>
                    </div>

                    {/* Other Details */}
                    <div className="text-sm sm:text-base md:text-lg font-normal text-gray-500">
                      <p>{item.other_details}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Next Slide Button */}
        <button className="bg-transparent border-none z-10" onClick={nextSlide}>
          <ArrowRightIcon
            className={`w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 text-gray-600`}
          />
        </button>
      </div>

      {/* Dots Indicator */}
      <div className="flex justify-center mt-8">
        {carouselContent.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`h-3 w-3 mx-2 border-none rounded-full ${
              index === currentIndex
                ? "bg-gray-700"
                : "bg-gray-400 hover:bg-gray-500"
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Champion;
